import { useState, useEffect } from 'react'
import UseAlertComponent from '../../components/moleculesComponents/alert/UseAlertComponent'
import { TutenTable } from '../../components/Table'
import { getTemplates } from '../../services/templateServices'
import { Actions, Columns } from './constants'
import messages from './messages'
import DialogComponent from '../../components/moleculesComponents/dialogComponent/DialogComponent'
import ModalServicesCCBulkLoad from '../Modal/ModalServicesCCBulkLoad'
import { FormattedMessage } from 'react-intl'
import { bulkLoadGenerateDocument } from '../BulkLoad/BulkLoadServices'
import { useForm } from 'react-hook-form'

const TemplatesMaintainer = () => {
  const { openSnackbar, AlertComponent } = UseAlertComponent()
  const [refresh] = useState(false)
  const [bulkLoadModalOpen, setBulkLoadModalOpen] = useState(false)
  const [idSucursal, setIdSucursal] = useState([])
  const [titleBulkLoad, setTitleBulkLoad] = useState('')
  const [refreshServices, setRefreshServices] = useState(false)
  const [businessUnitList] = useState(
    JSON.parse(localStorage.getItem('businessUnits')),
  )
  const [cities, setCities] = useState([])
  const [busSelected, setBusSelected] = useState([])
  const [providerSelected, setProviderSelected] = useState([])
  const [selectedAll, setSelectedAll] = useState(false)

  const [isIconDisabled, setIsIconDisabled] = useState(false)
  const [showIconWaiting, setShowIconWaiting] = useState(false)

  const onSuccess = (title, msg) => {
    openSnackbar(msg, 'success', title, 10000)
  }
  const onError = (title, msg) => {
    openSnackbar(msg, 'error', title, 10000)
  }
  const onRefreshService = async option => {
    if (option.status == 200) {
      onSuccess(option.title, option.msg)
      setRefreshServices(!refreshServices)
    } else onError(option.title, option.msg)
  }
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  })
  const downloadDocument = async dataForm => {
    let controlResponse = {
      onSuccess,
      onError,
      setBulkLoadModalOpen,
    }
    await bulkLoadGenerateDocument(
      { ...dataForm, proListId: null },
      idSucursal,
      {
        cities,
        providers: providerSelected,
      },
      titleBulkLoad,
      busSelected,
      controlResponse,
      selectedAll,
    )
    setIsIconDisabled(false)
    setShowIconWaiting(false)
  }

  const downloadDoc = row => {
    const arr = Actions(
      row,
      setBulkLoadModalOpen,
      setTitleBulkLoad,
      setIsIconDisabled,
      setShowIconWaiting,
    )
    const option = arr[0]
    option.action(row)
  }

  useEffect(() => {
    if (!bulkLoadModalOpen) {
      setIsIconDisabled(false)
      setShowIconWaiting(false)
    }
  }, [bulkLoadModalOpen])

  return (
    <>
      <TutenTable
        showDownload={true}
        downloadDoc={downloadDoc}
        columns={Columns}
        getData={getTemplates}
        refresh={refresh}
        getSnackbar={() =>
          openSnackbar(messages.errorGetData, 'error', messages.error)
        }
        showPagination={false}
        isIconDisabled={isIconDisabled}
        setIsIconDisabled={setIsIconDisabled}
        showIconWaiting={showIconWaiting}
        setShowIconWaiting={setShowIconWaiting}
      />
      <AlertComponent />

      <DialogComponent
        open={bulkLoadModalOpen}
        setOpen={setBulkLoadModalOpen}
        title={`Asociación masiva de ${titleBulkLoad}`}
        modalSize={'1053px'}
        contentSize={'100%'}
        msg={
          <ModalServicesCCBulkLoad
            onRefreshService={onRefreshService}
            idSucursal={idSucursal}
            setIdSucursal={setIdSucursal}
            setTitleBulkLoad={setTitleBulkLoad}
            titleBulkLoad={titleBulkLoad}
            businessUnitList={businessUnitList.map(bu => bu.id)}
            setCities={setCities}
            setBusSelected={setBusSelected}
            selectedAll={selectedAll}
            setSelectedAll={setSelectedAll}
            setProviderSelected={setProviderSelected}
            setValue={setValue}
            control={control}
            watch={watch}
            bulkLoadModalOpen={bulkLoadModalOpen}
          />
        }
        buttons={[
          {
            text: <FormattedMessage id="app.button.cancel" />,
            action: () => {
              setBulkLoadModalOpen(false)
            },
            variant: 'outlined',
          },
          {
            text: <FormattedMessage id="app.bulk.load.btn.download.doc" />,
            action: handleSubmit(downloadDocument),
            variant: 'contained',
            disabled: !isValid,
          },
        ]}
      />
    </>
  )
}

export default TemplatesMaintainer
