import { CircularProgress } from '@material-ui/core'
import { useState, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import UseAlertComponent from '../../components/moleculesComponents/alert/UseAlertComponent'
import PublishIcon from '@material-ui/icons/Publish'
import { useDropzone } from 'react-dropzone'
import { useStyles } from './styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { MaterialButton } from '../../components/atomComponents/Buttons'
import {
  EndpointByTypeOfLoad,
  typeOfLoadFiltered,
  importProviderExcelFile,
  importProfessionalExcelFile,
  errorMessages,
} from './constants'
import SimpleTable from '../../components/moleculesComponents/simpleTable/SimpleTable.jsx'
import { businessUnits } from '../../services/professionalServices'
import { SelectWithController } from '../../components/atomComponents/selects/SelectWithController'
import Select from '../../components/atomComponents/selects/select'
import { providersCharge } from '../../services/ProviderServices'
import { professionalChargeV2 } from '../../services/professionalServices'

const TemplateUpload = props => {
  const classes = useStyles()
  const { openSnackbar, AlertComponent } = UseAlertComponent()
  const [file, setFile] = useState(null)
  const [loadingFile, setLoadingFile] = useState(false)
  const [disableBU, setDisableBU] = useState(false)
  const [isRequiredBU, setIsRequiredBU] = useState(true)
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm({ mode: 'onChange' })

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setLoadingFile(true)
      acceptedFiles.forEach(async newFile => {
        setFile(newFile)
        setLoadingFile(false)
      })
    } else {
      openSnackbar(messages.fileFormatWrong, 'error', messages.loadError)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.xlsx',
    multiple: false,
  })

  const typeOfLoad = typeOfLoadFiltered(props.permissions)
  const handleSave = async data => {
    console.log('pepeeee')
    console.log(data)
    if (data.typeUpload === 3) {
      try {
        let resImport = await importProviderExcelFile(file)
        if (resImport.success) {
          let resUpload = await providersCharge(resImport.body, file.name)
          if (resUpload.status === 200) {
            openSnackbar(
              messages.successfulFileUpload,
              'success',
              messages.loadSuccessful,
              10000,
            )
            deleteFileSelected()
          } else
            openSnackbar(
              messages.errorGetData,
              'error',
              messages.loadError,
              6000,
            )
        }
      } catch (e) {
        openSnackbar(
          messages.errorToGenerateData,
          'error',
          messages.loadError,
          6000,
        )
      }
    } else if (data.typeUpload === 1) {
      try {
        const { success, body, rowsWithErrors } =
          await importProfessionalExcelFile(file)

        if (!success) {
          openSnackbar(messages.errorGeneric, 'error', messages.error, 10000)
          return
        }

        if (rowsWithErrors.length > 0) {
          openSnackbar(
            messages.errorUploadNulls,
            'error',
            messages.errorUploadNullsTitle,
            10000,
          )
          await professionalChargeV2(body, file.name)
          return
        }

        const resUpload = await professionalChargeV2(body, file.name)

        if (resUpload?.status != 200) {
          if (resUpload.data?.message) {
            openSnackbar(resUpload.data?.message, 'error', messages.loadError)
            return
          }
          openSnackbar(messages.errorGeneric, 'error', messages.error)
        }

        openSnackbar(
          messages.successfulFileUpload,
          'success',
          messages.loadSuccessful,
          10000,
        )
        deleteFileSelected()
      } catch (e) {
        if (e.message === errorMessages.HEADERS_NOT_FOUNT) {
          openSnackbar(
            messages.errorInHeaders,
            'error',
            messages.errorInHeadersTitle,
          )
          return
        }

        openSnackbar(messages.errorGeneric, 'error', messages.error)
      }
    } else {
      let res = await EndpointByTypeOfLoad[data.typeUpload](
        file,
        data.businessUnit,
      )
      if (res.status === 200) {
        openSnackbar(
          messages.successfulFileUpload,
          'success',
          messages.loadSuccessful,
          10000,
        )
        deleteFileSelected()
      } else if (res.status === 404) {
        openSnackbar(messages.errorSendFile, 'error', messages.loadError)
      } else {
        openSnackbar(messages.couldNotSend, 'error', messages.loadError)
      }
    }
  }

  const deleteFileSelected = () => {
    setFile(null)
    setLoadingFile(false)
    reset()
  }

  const handleSelectBulkLoad = value => {
    if (
      value === 1 ||
      value === 3 ||
      value === 4 ||
      value === 5 ||
      value === 6
    ) {
      setIsRequiredBU(false)
      setDisableBU(true)
    } else {
      setIsRequiredBU(true)
      setDisableBU(false)
    }
  }

  let classisDragActive = isDragActive ? 'active' : ''
  let classLoadingFile = loadingFile ? `${classes.loading}` : ''
  let classLoadFile = loadingFile ? '' : `${classes.loading}`
  let buttonOrProgress = !isSubmitting ? (
    <MaterialButton
      text={<FormattedMessage {...messages.sendFile} />}
      style={{ float: 'right', marginTop: '10px' }}
      onClick={() => {
        handleSubmit(handleSave)()
      }}
      disabled={!isValid || isSubmitting}
    />
  ) : (
    <CircularProgress className={`${classes.sendingFile}`} />
  )
  return (
    <>
      {!file ? (
        <div
          {...getRootProps()}
          className={`${classes.dropzone} ${classisDragActive}`}
        >
          <input {...getInputProps()} placeholder="Carga de archivos" />
          <p className={classes.dropTitle}>
            <FormattedMessage {...messages.dropTitle} />
          </p>
          <div className={`${classes.dropText} ${classLoadingFile}`}>
            <PublishIcon htmlColor={'rgba(0, 0, 0, 0.54)'} fontSize="large" />
            <p style={{ margin: '0' }}>
              <FormattedMessage {...messages.dropTextFirst} />
            </p>
            <p style={{ margin: '0' }}>
              <FormattedMessage {...messages.dropTextTwo} />
            </p>
          </div>
          <CircularProgress className={classLoadFile} />
          <p className={classes.dropInfo}>
            <FormattedMessage {...messages.dropInfo} />
          </p>
        </div>
      ) : (
        <>
          <SimpleTable
            icon={
              <div
                onClick={() => {
                  !isSubmitting && deleteFileSelected()
                }}
              >
                <DeleteIcon htmlColor={'rgba(0, 0, 0, 0.54)'} />
              </div>
            }
            fileName={file?.name}
            fileSize={file?.size}
            fileType={
              <SelectWithController
                data={businessUnits()}
                control={control}
                name={'businessUnit'}
                placeholder={'Selecciona'}
                helperText={
                  <FormattedMessage {...messages.helperBusinessUnit} />
                }
                disabled={disableBU}
                require={isRequiredBU}
                size={'small'}
              />
            }
            typeUpload={
              <Controller
                name={'typeUpload'}
                key={'typeUploadCtrlKey0'}
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <Select
                      key={'typeUploadKey0'}
                      id={'typeUploadId0'}
                      label={''}
                      value={value}
                      onChange={e => {
                        handleSelectBulkLoad(e.target.value)
                        onChange(e)
                      }}
                      error={!!error}
                      required={true}
                      data={typeOfLoad}
                      placeholder={'Selecciona'}
                      helperText={
                        <FormattedMessage {...messages.helperTypeUpload} />
                      }
                      fullWidth
                      inputRef={ref}
                      size={'small'}
                    ></Select>
                  )
                }}
                rules={{ required: true }}
              />
            }
          />
          {buttonOrProgress}
        </>
      )}
      <AlertComponent />
    </>
  )
}

export default TemplateUpload
