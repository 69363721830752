/**
 * Este es un select con auto completado
 * el cual maneja el llamado interno de una api(pasada por parametro como callback)
 * para obtener las opciones
 */
import React, { useEffect, useState } from 'react'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { Controller } from 'react-hook-form'
import SelectAutoComplete from './selectAutoComplete'
import { string, bool, func, object, number } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import CustomCheckbox from '../Checkbox'

const useStyles = makeStyles(theme => ({
  overwriteOption: {
    height: '32px',
  },
  inputRoot: {
    '&.MuiOutlinedInput-root': {
      padding: '7.5px',
    },
  },
  notInputRoot: {},
  overWriteChip: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 80,
    margin: '0',
    boxSizing: 'content-box',
  },
  rootCheckbox: {
    color: theme.palette.action.active || 'rgba(0,0,0, 0.54)',
  },
  indeterminate: {
    color: theme.palette.primary.main,
    '&.MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  },
}))

const AsyncAutoComplete = props => {
  const classes = useStyles()
  const filter = createFilterOptions()
  const {
    name,
    control,
    disabled,
    label,
    placeholder,
    variant,
    required,
    helperText,
    limitTags,
    multiple,
    validate,
    cbEndpoint,
    setSelectedAll,
    selectedAll,
  } = props

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [initiated, setInitiated] = useState(false)

  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value

  const handlerEndpoint = async value => {
    const res = await cbEndpoint(value)
    if (res.status === 200) {
      setLoading(false)
      setOptions(res.data)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!initiated) {
      setLoading(true)
      handlerEndpoint()
      setInitiated(true)
    }
  })

  const handlerChange = (selectedOptions, reason, onChange) => {
    if (reason === 'clear') {
      onChange(selectedOptions)
    } else {
      if (selectedOptions.find(option => option.value === 'select-all')) {
        if (selectedAll) {
          setSelectedAll(false)
          return onChange([])
        }
        setSelectedAll(true)
        return onChange([...options])
      } else {
        setSelectedAll(false)
        return onChange(selectedOptions)
      }
    }
  }
  const handleInputChange = value => {
    if (value.length > 3) {
      handlerEndpoint(value)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            multiple={multiple}
            limitTags={limitTags}
            label={label}
            value={value || []}
            placeholder={placeholder || 'Selecciona'}
            getOptionLabel={option => (option.label ? option.label : '')}
            onChange={(...args) => {
              handlerChange(args[1], args[2], onChange)
            }}
            onInputChange={(event, value) => {
              handleInputChange(value)
            }}
            getOptionSelected={getOptionSelected}
            disableCloseOnSelect
            classes={{
              option: classes.overwriteOption,
              inputRoot:
                value?.length > 0 ? classes.inputRoot : classes.notInputRoot,
            }}
            ChipProps={{
              classes: {
                label: classes.overWriteChip,
              },
            }}
            getLimitTagsText={num => <>{`(+${num})`}</>}
            options={options}
            disabled={disabled}
            required={required}
            error={!!error}
            helperText={helperText}
            loading={loading}
            noOptionsText={'No hay opciones disponibles.'}
            filterOptions={(op, params) => {
              const filtered = filter(op, params)
              return options.length > 0
                ? [
                    { label: 'Seleccionar todo', value: 'select-all' },
                    ...filtered,
                  ]
                : []
            }}
            renderOption={(option, { selected }) => (
              <>
                <CustomCheckbox
                  style={{ marginLeft: option.value === 'select-all' ? -8 : 8 }}
                  checked={
                    option.value === 'select-all' ? selectedAll : selected
                  }
                  indeterminate={
                    option.value === 'select-all' &&
                    value?.length > 0 &&
                    !selectedAll
                  }
                  classes={{
                    root: classes.rootCheckbox,
                    indeterminate: classes.indeterminate,
                  }}
                />
                {option.label}
              </>
            )}
            renderInput={params => {
              return (
                <SelectAutoComplete
                  params={params}
                  label={label}
                  placeholder={placeholder || 'Selecciona'}
                  variant={variant}
                  required={required}
                  error={!!error}
                  helperText={helperText}
                  inputRef={ref}
                  loading={loading}
                />
              )
            }}
          />
        )
      }}
      rules={{ required: required, validate: validate }}
    />
  )
}

AsyncAutoComplete.propTypes = {
  name: string, // array with object options
  control: object, // object control react-hook-form
  label: string,
  placeholder: string,
  variant: string,
  required: bool,
  helperText: string,
  validate: func,
  cbEndpoint: func,
  limitTags: number,
}

AsyncAutoComplete.defaultProps = {
  required: false,
  helperText: '',
  validate: () => {},
  limitTags: 2,
}

export default AsyncAutoComplete
